import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  setItemType,
  fetchUserEvents,
  toggleModal,
  searchUpdate,
  getSelectedItem,
  getEventListSameVenue
} from "../../../actions";
import { findMatches, filterEvents } from "../../../utils";
import ProfileComponent from "./Component";

const mapStateToProps = ({
  itemType,
  userEvents,
  isModalOpen,
  findUser,
  searchValue,
  selectedItem,
  eventListSameVenue,
  isOnline
}) => ({
  itemType,
  isModalOpen,
  matches: findMatches(searchValue, userEvents),
  doesUserExist: findUser,
  searchString: searchValue,
  userEvents: filterEvents(userEvents),
  selectedItem,
  eventListSameVenue,
  isOnline
});

const mapDispatchToProps = {
  setItemType,
  fetchUserEvents,
  toggleModal,
  searchUpdate,
  getSelectedItem,
  getEventListSameVenue
};

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhancer = compose(
  connectToStore,
  withRouter
);

export default enhancer(ProfileComponent);
