import React from "react";
import styled from "styled-components";
import icon from "../../icons/icon.svg";

const Logo = styled.img`
  background-color: ${({ theme }) => theme.color.link};
  padding: 0.75em;
  border-radius: 10px;
  width: 4em;
`;

const Placeholder = () => {
  return <Logo src={icon} />;
};

export default Placeholder;
