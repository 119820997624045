import React from "react";
import { func, bool, string, number } from "prop-types";
import styled from "styled-components";

import TagStyle from "../Tag";

const Label = styled.label`
  ${TagStyle};
`;

const Number = styled.span`
  display: inline-block;
  margin-left: 0.25em;
  margin-right: 0;
  min-width: 1em;
  padding: 0 0.25em;
  text-align: center;
  color: white;
  border-radius: 999px;
  font-size: 0.875em;
  background-color: ${({ theme }) => theme.color.border};
  font-family: ${({ theme }) => theme.font.secondary};
`;

const Formwrapper = styled.div`
  display: inline-block;
  border-color: black;
  color: black;
  white-space: nowrap;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  label {
    cursor: pointer;
  }

  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    &:focus ~ label {
      color: black;
      border-color: black;

      > span {
        background-color: black;
      }
    }
  }
`;

const Filter = ({ onChange, checked, label, numberOfResults, id, className }) =>
  numberOfResults === 0 ? null : (
    <Formwrapper>
      <input
        type="checkbox"
        checked={checked}
        id={id}
        onChange={() => {
          onChange(id);
        }}
      />
      <Label
        htmlFor={id}
        className={checked ? `bg--${className}` : `border--${className}`}
      >
        {label}
        <Number className={`bg--${className}`}>{numberOfResults}</Number>
      </Label>
    </Formwrapper>
  );

Filter.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  label: string.isRequired,
  className: string,
  numberOfResults: number.isRequired,
  id: string.isRequired
};

Filter.defaultProps = {
  className: "default"
};

export default Filter;
