import React from "react";
import styled, { withTheme } from "styled-components";
import {
  string,
  func,
  bool,
  object,
  arrayOf,
  shape,
  objectOf
} from "prop-types";
import { format } from "date-fns";

import { getListBy, sortOrder } from "../../../utils";
import { LABEL, DATE_FORMAT } from "../../../constants";
import { ReportContainer, Outer, Inner } from "..";
import {
  ButtonReport,
  Heading,
  ListComponent,
  EventItem,
  Startdate,
  Seperator,
  More,
  ReportList
} from "../..";
import SVGIcon from "../../../icons";

const EventReport = ({
  events,
  isOpened,
  onItemClick,
  sortEventsUpcoming,
  sortEventsPast,
  sortDirectionEvents,
  theme
}) => {
  const {
    sortDirectionEventsUpcoming,
    sortDirectionEventsPast
  } = sortDirectionEvents;

  const listItemsEventsUpcoming = getListBy(LABEL.upcoming, events);
  const sortedListEventsUpcoming = sortOrder(
    listItemsEventsUpcoming,
    sortDirectionEventsUpcoming
  );
  const listItemsEventsUpcomingLength = listItemsEventsUpcoming.length;

  const listItemsEventsPast = getListBy(LABEL.past, events);
  const sortedListEventsPast = sortOrder(
    listItemsEventsPast,
    sortDirectionEventsPast
  );
  const listItemsEventsPastLength = listItemsEventsPast.length;

  const EventList = styled(ReportList)`
    ${theme.media.medium`
      column-count: ${({ columnCount }) => (columnCount > 10 ? "2" : "")};

      li {
        break-inside: avoid;
      }
    `}
  `;

  return (
    <Outer isOpened={isOpened}>
      <Inner>
        <ButtonReport reset disabled>
          <Heading as="h2">List of events ({events.length})</Heading>
        </ButtonReport>
        <ReportContainer>
          <ListComponent
            title={LABEL.upcoming}
            listItemsLength={listItemsEventsUpcomingLength}
            sortDirection={sortDirectionEventsUpcoming}
            onSortClick={sortEventsUpcoming}
            onItemClick={onItemClick}
          >
            <EventList columnCount={listItemsEventsUpcomingLength}>
              {sortedListEventsUpcoming.map((item, index) => (
                <EventItem key={index.toString()}>
                  <Startdate>
                    {format(new Date(item.startdate), DATE_FORMAT)}
                  </Startdate>
                  <Seperator> - </Seperator>

                  <More
                    reset
                    onClick={() => onItemClick({ index, selectedItem: item })}
                  >
                    {item.title}
                    <SVGIcon name="popup" width={14} fill={theme.color.link} />
                  </More>
                </EventItem>
              ))}
            </EventList>
          </ListComponent>

          <ListComponent
            title={LABEL.past}
            listItemsLength={listItemsEventsPastLength}
            sortDirection={sortDirectionEventsPast}
            onSortClick={sortEventsPast}
            onItemClick={onItemClick}
          >
            <EventList columnCount={listItemsEventsPastLength}>
              {sortedListEventsPast.map((item, index) => (
                <EventItem key={index.toString()}>
                  <Startdate>
                    {format(new Date(item.startdate), DATE_FORMAT)}
                  </Startdate>
                  <Seperator> - </Seperator>

                  <More
                    reset
                    onClick={() => onItemClick({ index, selectedItem: item })}
                  >
                    {item.title}
                    <SVGIcon name="popup" width={14} fill={theme.color.link} />
                  </More>
                </EventItem>
              ))}
            </EventList>
          </ListComponent>
        </ReportContainer>
      </Inner>
    </Outer>
  );
};

EventReport.propTypes = {
  events: arrayOf(object).isRequired,
  onItemClick: func.isRequired,
  isOpened: bool,
  sortEventsUpcoming: func,
  sortEventsPast: func,
  sortDirectionEvents: shape({
    sortDirectionEventsUpcoming: string,
    sortDirectionEventsPast: string
  }).isRequired,
  theme: objectOf(object)
};

EventReport.defaultProps = {
  isOpened: false,
  sortEventsUpcoming: null,
  sortEventsPast: null,
  theme: null
};

export default withTheme(EventReport);
