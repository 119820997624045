import styled from "styled-components";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { BASE_URL } from "../../constants";
import { sortOrder } from "../../utils";

import { Button } from "..";
import List from "../List";

export default class ListLineup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFullLineup: false
    };
  }

  toggleFullLineup() {
    const { showFullLineup } = this.state;
    this.setState({ showFullLineup: !showFullLineup });
  }

  render() {
    const { fullLineup, showItems, sortDirection, sortType } = this.props;
    const { showFullLineup } = this.state;

    let lineUpToShow = sortOrder(fullLineup, sortDirection, sortType);
    let buttonText;
    let columnNumberSmall = 2;
    let columnNumber = 4;

    if (showItems) {
      lineUpToShow = showFullLineup
        ? fullLineup
        : fullLineup.slice(0, showItems);

      if (showFullLineup) {
        buttonText = `view first ${showItems} ▲`;
      } else {
        buttonText = "full line up ▼";
        columnNumber = 0;
        columnNumberSmall = columnNumber;
      }
    } else {
      lineUpToShow = fullLineup;
      columnNumber = 2;
    }

    const LineupList = styled(List)`
      column-count: ${columnNumberSmall};

      ${({ theme }) => theme.media.medium`
        column-count: ${columnNumber};
      `}
    `;

    return (
      <Fragment>
        <LineupList>
          {lineUpToShow.map((artist, id) => (
            <li key={`${id.toString()}${artist.name}`}>
              {artist.href ? (
                <a href={`${BASE_URL}${artist.href}`}>{artist.name}</a>
              ) : (
                artist.name
              )}
            </li>
          ))}
        </LineupList>
        {showItems.length > 0 && fullLineup.length > showItems && (
          <Button
            style={{ marginTop: "1em" }}
            small
            onClick={() => this.toggleFullLineup()}
          >
            {buttonText}
          </Button>
        )}
      </Fragment>
    );
  }
}

ListLineup.propTypes = {
  fullLineup: PropTypes.arrayOf(PropTypes.object),
  showItems: PropTypes.string,
  sortDirection: PropTypes.string,
  sortType: PropTypes.string
};

ListLineup.defaultProps = {
  fullLineup: PropTypes.array,
  showItems: "",
  sortDirection: "asc",
  sortType: ""
};
