import React from "react";
import { Wrapper, Outer, Placeholder } from "../components";

const HomePage = () => (
  <Wrapper>
    <Outer>
      {/*
      <Heading as="h1">S E A R C H</Heading>
      <SearchUser />
       */}
      <Placeholder />
    </Outer>
  </Wrapper>
);

export default HomePage;
