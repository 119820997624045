import { combineReducers } from "redux";
import itemType from "./itemType";
import isOnline from "./connection";
import isModalOpen from "./modal";
import findUser from "./findUser";
import searchValue from "./searchValue";
import searchUserValue from "./searchUserValue";
import selectedItem from "./selectedItem";
import eventListSameVenue from "./eventListSameVenue";
import userEvents from "./userEvents";
import sortDirectionEvents from "./sortEvents";
import isVenueReportOpen from "./toggleVenueReport";
import showFullVenueList from "./showFullVenueList";
import sortDirectionVenues from "./sortVenues";

export default combineReducers({
  isOnline,
  itemType,
  isModalOpen,
  findUser,
  searchValue,
  searchUserValue,
  selectedItem,
  eventListSameVenue,
  userEvents,
  sortDirectionEvents,
  isVenueReportOpen,
  showFullVenueList,
  sortDirectionVenues
});
