import styled, { css } from "styled-components";

export const Heading = styled.h1`
  margin: 0.25em 0;

  ${props =>
    props.as === "h1" &&
    css`
      font-size: 1.5em;
      font-variation-settings: "wght" 600;

      ${({ theme }) => theme.media.medium`
        font-size: 2em;
      `}
    `};

  ${props =>
    props.as === "h2" &&
    css`
      font-variation-settings: "wght" 600;
    `};

  ${props =>
    props.as === "h3" &&
    css`
      font-variation-settings: "wght" 500;

      &:first-letter {
        text-transform: capitalize;
      }
    `};
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;
