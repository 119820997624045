import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { pageTitle } from "../utils";

// import SearchUser from "./SearchUser";
import { Heading, Wrapper, Outer, Placeholder } from "../components";

const NotFoundPage = () => {
  pageTitle("Not found");
  return (
    <Wrapper>
      <Outer>
        <Heading as="h1">Page not found</Heading>
        <p>
          <Link to="/" href="/">
            Go back home
          </Link>
        </p>
        {/* <SearchUser /> */}
        <Placeholder />
      </Outer>
    </Wrapper>
  );
};

export default withRouter(NotFoundPage);
