import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { highlightString } from "../../utils";

import { ResultItem } from "..";

const List = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
`;

const ResultList = ({ items, itemHighlight, itemOpened, onItemClick }) => (
  <List>
    {items.map((item, index) => {
      const highlightName = highlightString(item.name, itemHighlight);
      const highlightTitle = highlightString(item.title, itemHighlight);
      const highlightVenue = highlightString(item.venue, itemHighlight);
      const singleArtist = item.details.artists.filter(
        artist => item.name === artist.name
      );

      return (
        <ResultItem
          key={index.toString()}
          index={index}
          itemOpened={itemOpened}
          item={item}
          highlightName={highlightName}
          highlightTitle={highlightTitle}
          highlightVenue={highlightVenue}
          singleArtist={singleArtist}
          onItemClick={() => {
            onItemClick(index);
          }}
        />
      );
    })}
  </List>
);

ResultList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  itemHighlight: PropTypes.string.isRequired,
  itemOpened: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default ResultList;
