import React, { Fragment, Component } from "react";
import { string, arrayOf, object } from "prop-types";
import styled from "styled-components";

import { filterGroups, allFilterIds, applyAllFilters } from "../../filters";
import { SORTING } from "../../constants";
import { sortOrder } from "../../utils";

import {
  Button,
  ButtonIcon,
  Filter,
  Heading,
  HeadingContainer,
  Wrapper,
  ListFilters,
  ResultList
} from "..";

const Container = styled(Wrapper)`
  padding: ${({ theme }) => theme.spacing.vertical}
    ${({ theme }) => theme.spacing.outer};
`;

const Options = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
  position: relative;

  ul {
    margin-top: 1em;
  }

  button {
    position: absolute;
    right: 0;
    top: -0.5em;
    background-color: ${({ theme }) => theme.color.medium};
    height: 2em;
  }

  ${({ theme }) => theme.media.medium`
    ul {
      margin-top: 0;
    }

    button {
      top: -1.5em;
      right: -1.5em;
    }
  `}
`;

export default class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      selectedMatch: -1,
      matches: props.matchesFromProps,
      sortDirection: props.sortDirection
    };

    this.state = this.initialState;

    allFilterIds().forEach(filterId => {
      const { filter } = this.state;
      this.state = {
        ...this.state,
        filter: {
          ...filter,
          [filterId]: false
        }
      };
    });
  }

  componentDidUpdate(prevProps) {
    const { searchString, matchesFromProps } = this.props;
    const { matches } = this.state;
    if (searchString !== prevProps.searchString) {
      this.resetSelectedMatch();
    }
    if (matchesFromProps.toString() !== matches.toString()) {
      this.updateMatches();
    }
  }

  onFilterToggled = id => {
    const { filter } = this.state;
    this.setState(prevState => ({
      ...prevState,
      selectedMatch: this.initialState.selectedMatch,
      filter: {
        ...filter,
        [id]: !prevState.filter[id]
      }
    }));
  };

  displayArtistDetails = index => {
    const { selectedMatch } = this.state;
    if (index === selectedMatch) {
      this.resetSelectedMatch();
    } else {
      this.setState({
        selectedMatch: index
      });
    }
  };

  updateMatches() {
    const { matchesFromProps } = this.props;
    this.setState(() => ({
      matches: matchesFromProps
    }));
  }

  resetSelectedMatch() {
    this.setState(() => ({
      selectedMatch: this.initialState.selectedMatch
    }));
  }

  changeOrder() {
    const { sortDirection } = this.state;
    this.setState({
      selectedMatch: this.initialState.selectedMatch,
      sortDirection:
        sortDirection === SORTING.desc.direction
          ? SORTING.asc.direction
          : SORTING.desc.direction
    });
  }

  render() {
    const { searchString } = this.props;
    const { selectedMatch, matches, sortDirection, filter } = this.state;
    const filterSelected =
      allFilterIds().filter(filterId => filter[filterId]).length > 0;

    const filteredResults = filterSelected
      ? applyAllFilters(matches, filter)
      : matches;

    const sortedList = sortOrder(filteredResults, sortDirection);

    let filterOptions = {};
    filterGroups.forEach(group => {
      filterOptions = {
        ...filterOptions,
        ...group.filters
      };
    });

    const numberOfItemsWhenSelected = {};
    const filterIdentifiers = Object.keys(filterOptions);
    filterIdentifiers.forEach(key => {
      const typeFilterFunction = filterOptions[key];
      const curFilter = {
        ...filter,
        [key]: true
      };

      numberOfItemsWhenSelected[key] = applyAllFilters(
        matches,
        curFilter
      ).filter(typeFilterFunction);
    });

    /*
        what the above does:

        const pastItems = this.applyFilter(results,{
            filterPastIsSelected:this.state.filterPastIsSelected,
            filterUpcomingIsSelected: this.state.filterUpcomingIsSelected,
            filterFestivalIsSelected: this.state.filterFestivalIsSelected,
            filterConcertIsSelected: this.state.filterConcertIsSelected,
            filterPastIsSelected:true
        })

        const resultingItems = pastItems.filter(pastFilter)

        const allItems={}
        allItems['filterPastIsSelected']=resultingItems;

    */

    if (searchString === null || searchString.length < 2) return null;
    return (
      <Container>
        {sortedList.length === 0 ? (
          <Heading as="h2">
            No results found for <mark>{searchString}</mark>
          </Heading>
        ) : null}

        <Fragment>
          <Options>
            {sortedList.length ? (
              <ListFilters>
                {filterGroups.map(filterGroup => (
                  <li key={Math.random()}>
                    {filterGroup.labels.map((label, index) => (
                      <Filter
                        id={filterGroup.ids[index]}
                        checked={filter[filterGroup.ids[index]]}
                        label={label}
                        className={filterGroup.className[index]}
                        numberOfResults={
                          numberOfItemsWhenSelected[filterGroup.ids[index]]
                            .length
                        }
                        key={label}
                        onChange={this.onFilterToggled}
                      />
                    ))}
                  </li>
                ))}
              </ListFilters>
            ) : null}

            {filterSelected && (
              <Button
                small
                onClick={() => {
                  let resetObject = {};
                  allFilterIds().forEach(filterId => {
                    resetObject = {
                      ...resetObject,
                      [filterId]: false
                    };
                  });
                  this.setState(() => ({ filter: resetObject }));
                }}
              >
                ⟲
              </Button>
            )}
          </Options>

          {sortedList.length ? (
            <Fragment>
              <HeadingContainer>
                <Heading as="h2">Results ({sortedList.length})</Heading>
                <ButtonIcon small onClick={() => this.changeOrder()}>
                  {sortDirection === SORTING.asc.direction
                    ? SORTING.asc.icon
                    : SORTING.desc.icon}
                </ButtonIcon>
              </HeadingContainer>

              <ResultList
                items={sortedList}
                itemHighlight={searchString}
                itemOpened={selectedMatch}
                onItemClick={this.displayArtistDetails}
              />
            </Fragment>
          ) : null}
        </Fragment>
      </Container>
    );
  }
}

SearchResults.propTypes = {
  searchString: string,
  matchesFromProps: arrayOf(object).isRequired,
  sortDirection: string
};

SearchResults.defaultProps = {
  searchString: null,
  sortDirection: "asc"
};
