import { connect } from "react-redux";
import {
  toggleVenueReport,
  toggleVenueList,
  sortVenuesList,
  sortVenuesOther
} from "../../../actions";
import VenueComponent from "./Component";

const mapStateToProps = ({
  isVenueReportOpen,
  showFullVenueList,
  sortDirectionVenues
}) => ({
  isVenueReportOpen,
  showFullVenueList,
  sortDirectionVenues
});

const mapDispatchToProps = {
  toggleVenueReport,
  toggleVenueList,
  sortVenuesList,
  sortVenuesOther
};

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connectToStore(VenueComponent);
