export default {
  border: "#b1b1b1",
  light: "#f3f3f3",
  medium: "#565656",
  dark: "#272727",
  link: "#e97778",

  past: "#7998c9",
  upcoming: "#68b5a0",
  concert: "#f1993f",
  festival: "#9379a5",
  support: "#b33434",

  success: "#16802f",
  danger: "#ff3f50",
  highlight: "#ffdd00"
};
