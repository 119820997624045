import { isAfter, isBefore, isSameDay } from "date-fns";
import { compose, reverse, sortBy } from "lodash/fp";
import { SORTING, PAGE_TITLE, LABEL } from "./constants";

export const normaliseChars = sentence =>
  sentence.replace(/[^A-Za-z0-9\-_]/g, ch => {
    const lower = ch.toLowerCase();
    if ("àáâãäå".indexOf(lower) >= 0) {
      return "a";
    }
    if ("æ".indexOf(lower) >= 0) {
      return "ae";
    }
    if ("ç€".indexOf(lower) >= 0) {
      return "c";
    }
    if ("èéêë€".indexOf(lower) >= 0) {
      return "e";
    }
    if ("ìíîï!".indexOf(lower) >= 0) {
      return "i";
    }
    if ("ñ".indexOf(lower) >= 0) {
      return "n";
    }
    if ("òóôõöø".indexOf(lower) >= 0) {
      return "o";
    }
    if ("$".indexOf(lower) >= 0) {
      return "s";
    }
    if ("ùúûü".indexOf(lower) >= 0) {
      return "u";
    }
    if ("ýÿ¥".indexOf(lower) >= 0) {
      return "y";
    }
    if ("œ".indexOf(lower) >= 0) {
      return "oe";
    }

    return ch;
  });

export const direction = dir => (dir === "desc" ? reverse : f => f);

export const validateAttr = sortType =>
  Object.values(SORTING.type).includes(sortType) ? sortType : SORTING.type.date;

export const sortByAttr = compose(
  sortBy,
  validateAttr
);

export const sortOrder = (
  listToOrder,
  sortDirection = "asc",
  attr = "startdate"
) => {
  if (Array.isArray(listToOrder)) {
    const sorter = compose(
      direction(sortDirection),
      sortByAttr(attr)
    );
    return sorter(listToOrder);
  }
  return undefined;
};

export const highlightString = (source, searchString) => {
  const normalisedEventName = normaliseChars(source);
  const highlightSource =
    source !== normalisedEventName ? normalisedEventName : source;
  const highlightStart = highlightSource
    .toLowerCase()
    .indexOf(searchString.toLowerCase());
  if (highlightStart < 0) return source;
  const highlightSubstring = source.substr(highlightStart, searchString.length);

  return source.replace(
    highlightSubstring,
    `<span className="hl">${highlightSubstring}</span>`
  );
};

export const checkUsername = username => {
  try {
    /* eslint-disable */
    return require(`./api/json/${username}.json`);
    /* eslint-enable */
  } catch (e) {
    return false;
  }
};

export const userProfileMap = {
  tivoli: "tivolivredenbrg",
  jasper: "jajajapser",
  linda: "websterr"
};

export const pageTitle = value =>
  (document.title =
    value && value.length ? `${value} - ${PAGE_TITLE}` : PAGE_TITLE);

export const getListBy = (type, listItems) => {
  if (type === LABEL.upcoming) {
    return listItems.filter(item => {
      if (
        isAfter(new Date(item.startdate), new Date()) ||
        isSameDay(new Date(item.startdate), new Date())
      ) {
        return item;
      }
      return null;
    });
  }

  if (type === LABEL.past) {
    return listItems.filter(item => {
      if (
        isBefore(new Date(item.startdate), new Date()) !==
        isSameDay(new Date(item.startdate), new Date())
      ) {
        return item;
      }
      return null;
    });
  }

  return listItems;
};

export const findMatches = (value, events) => {
  const matches = [];
  if (!value) return matches;
  const escapedWord = value.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&+");
  const regex = new RegExp(escapedWord, "gi");
  events.forEach(eventItem => {
    const eventTitle = eventItem.title;
    const mainAct = eventItem.artists.find(artist =>
      eventTitle.toLowerCase().includes(artist.name.toLowerCase())
    );

    const isEventMatch = normaliseChars(eventItem.title).match(regex) !== null;
    const isVenueMatch =
      eventItem.venue &&
      normaliseChars(eventItem.venue.name).match(regex) !== null;

    const matchedArtists = eventItem.artists.filter(
      artist => normaliseChars(artist.name).match(regex) !== null
    );

    const isArtistMatch = matchedArtists.length > 0;

    if (!matchedArtists.length) {
      matchedArtists.push({
        name: ""
      });
    }

    const isConcert = eventItem.artists.length <= 3;

    if (isArtistMatch || isEventMatch || isVenueMatch) {
      matchedArtists.forEach(matchedArtist => {
        matches.push({
          name: matchedArtist ? matchedArtist.name : "",
          title: eventItem.title,
          isConcert,
          isSupportAct:
            isConcert &&
            (Boolean(mainAct) && isArtistMatch && matchedArtist !== mainAct),
          venue: eventItem.venue.name,
          startdate: eventItem.startdate,
          details: {
            artists: eventItem.artists,
            isArtistMatch,
            isEventMatch,
            isVenueMatch,
            place: eventItem.venue.place,
            country: eventItem.venue.country,
            href: eventItem.href
          }
        });
      });
    }
  });
  return matches;
};

export const filterEvents = userEvents =>
  userEvents &&
  userEvents.filter(item => item.artists.length > 0 && item.venue);
