import React, { Fragment } from "react";
import { number, string, func, node, objectOf } from "prop-types";

import { SORTING } from "../../constants";
import { ButtonIcon, Heading, HeadingContainer } from "..";

const ListComponent = ({
  listItemsLength,
  title,
  onSortClick,
  sortDirection,
  style,
  children
}) => {
  const component = (
    <Fragment>
      <HeadingContainer>
        <Heading as="h3">
          {title} ({listItemsLength})
        </Heading>

        {onSortClick && listItemsLength > 0 && (
          <Fragment>
            <ButtonIcon small onClick={() => onSortClick()}>
              {sortDirection === SORTING.asc.direction
                ? SORTING.asc.icon
                : SORTING.desc.icon}
            </ButtonIcon>
          </Fragment>
        )}
      </HeadingContainer>
      {children}
    </Fragment>
  );

  return style ? <div style={style}>{component}</div> : component;
};

ListComponent.propTypes = {
  listItemsLength: number.isRequired,
  title: string.isRequired,
  sortDirection: string,
  onSortClick: func,
  children: node.isRequired,
  style: objectOf(string)
};

ListComponent.defaultProps = {
  sortDirection: null,
  onSortClick: null,
  style: null
};

export default ListComponent;
