import { handleActions } from "redux-actions";
import { sortEventsUpcoming, sortEventsPast } from "../actions";
import { SORTING } from "../constants";

const {
  asc: { direction: ASC },
  desc: { direction: DESC }
} = SORTING;

export default handleActions(
  {
    [sortEventsUpcoming]: (state, { payload }) => {
      switch (true) {
        case !payload && state.sortDirectionEventsUpcoming === ASC:
          return {
            ...state,
            sortDirectionEventsUpcoming: DESC
          };
        case !payload && state.sortDirectionEventsUpcoming === DESC:
          return {
            ...state,
            sortDirectionEventsUpcoming: ASC
          };
        case payload === ASC || payload === DESC:
          return { ...state, sortDirectionEventsUpcoming: payload };
        default:
          return state;
      }
    },
    [sortEventsPast]: (state, { payload }) => {
      switch (true) {
        case !payload && state.sortDirectionEventsPast === ASC:
          return {
            ...state,
            sortDirectionEventsPast: DESC
          };
        case !payload && state.sortDirectionEventsPast === DESC:
          return {
            ...state,
            sortDirectionEventsPast: ASC
          };
        case payload === ASC || payload === DESC:
          return { ...state, sortDirectionEventsPast: payload };

        default:
          return state;
      }
    }
  },
  {
    sortDirectionEventsUpcoming: ASC,
    sortDirectionEventsPast: DESC
  }
);
