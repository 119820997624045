import React from "react";
import { withTheme } from "styled-components";
import {
  bool,
  arrayOf,
  object,
  string,
  func,
  shape,
  objectOf
} from "prop-types";

import { sortOrder } from "../../../utils";
import { ReportContainer, Outer, Inner } from "..";
import {
  ButtonMoreVenues,
  ButtonReport,
  EventItem,
  More,
  Heading,
  ListComponent,
  ReportList
} from "../..";

import SVGIcon from "../../../icons";

const VenueReport = ({
  listItems,
  showItems,
  onItemClick,
  isVenueReportOpen,
  toggleVenueReport,
  showFullVenueList,
  toggleVenueList,
  sortDirectionVenues,
  sortVenuesList,
  sortVenuesOther,
  theme
}) => {
  const {
    sortDirectionVenuesList,
    sortDirectionVenuesOther
  } = sortDirectionVenues;
  const venueName = listItems.map(item => item.venue.name).sort();

  let name = null;
  let count = 0;
  const venueList = [];

  for (let i = 0; i < venueName.length; i += 1) {
    if (venueName[i] !== name) {
      if (count > 0) {
        venueList.push({
          name,
          count
        });
      }
      name = venueName[i];
      count = 1;
    } else {
      count += 1;
    }
  }

  const venueListOther = [];
  const venueListStripped = [];

  venueList.map(item => {
    const result = item.name.toLowerCase();

    if (
      result.includes("binnenstad") ||
      result.includes("glazen huis") ||
      result.includes("various") ||
      result.includes("centrum") ||
      result.includes("unknown")
    ) {
      venueListOther.push({
        name: item.name,
        count: item.count
      });
    } else {
      venueListStripped.push({
        name: item.name,
        count: item.count
      });
    }
    return null;
  });
  const sortedListWithVenues = sortOrder(
    venueListStripped,
    sortDirectionVenuesList,
    "count"
  );
  const sortedListWithVenuesOther = sortOrder(
    venueListOther,
    sortDirectionVenuesOther,
    "count"
  );

  const listWithVenues = showFullVenueList
    ? sortedListWithVenues
    : sortedListWithVenues.slice(0, showItems);

  const listWithVenuesOther = showFullVenueList
    ? sortedListWithVenuesOther
    : sortedListWithVenuesOther.slice(0, showItems);

  let buttonText;
  if (showFullVenueList) {
    buttonText = `view first ${showItems} ▲`;
  } else {
    buttonText = "full venue list ▼";
  }

  return (
    <Outer isOpened={isVenueReportOpen}>
      <Inner>
        <ButtonReport
          ref={button => button && button.blur()}
          onClick={() => toggleVenueReport()}
        >
          <Heading as="h2">List of venues ({venueList.length})</Heading>
          <span>{isVenueReportOpen ? "▲" : "▼"}</span>
        </ButtonReport>

        {isVenueReportOpen && (
          <ReportContainer>
            <div style={{ display: "flex" }}>
              <ListComponent
                title="Venues"
                listItemsLength={venueListStripped.length}
                sortDirection={sortDirectionVenuesList}
                onSortClick={sortVenuesList}
                style={{ flexBasis: "50%" }}
              >
                <ReportList>
                  {listWithVenues.map((item, index) => {
                    const eventListWithSameVenue = listItems.filter(
                      eventItem =>
                        eventItem.venue.name === listWithVenues[index].name
                    );
                    return (
                      <EventItem key={index.toString()}>
                        <More
                          reset
                          onClick={() =>
                            onItemClick({
                              index,
                              selectedItem: item,
                              eventListWithSameVenue
                            })
                          }
                        >
                          {item.name} ({item.count})
                          <SVGIcon
                            name="popup"
                            width={14}
                            fill={theme.color.link}
                          />
                        </More>
                      </EventItem>
                    );
                  })}
                </ReportList>
              </ListComponent>

              <ListComponent
                title="Other"
                listItemsLength={venueListOther.length}
                sortDirection={sortDirectionVenuesOther}
                onSortClick={sortVenuesOther}
                style={{ flexBasis: "50%" }}
              >
                <ReportList>
                  {listWithVenuesOther.map((item, index) => (
                    <EventItem key={index.toString()}>
                      {item.name} ({item.count})
                    </EventItem>
                  ))}
                </ReportList>
              </ListComponent>
            </div>

            {venueListStripped.length > showItems && (
              <ButtonMoreVenues onClick={() => toggleVenueList()}>
                {buttonText}
              </ButtonMoreVenues>
            )}
          </ReportContainer>
        )}
      </Inner>
    </Outer>
  );
};

VenueReport.propTypes = {
  isVenueReportOpen: bool.isRequired,
  toggleVenueReport: func.isRequired,
  listItems: arrayOf(object).isRequired,
  showItems: string.isRequired,
  onItemClick: func.isRequired,
  showFullVenueList: bool.isRequired,
  toggleVenueList: func.isRequired,
  sortVenuesList: func,
  sortVenuesOther: func,
  sortDirectionVenues: shape({
    sortDirectionVenuesList: string,
    sortDirectionVenuesOther: string
  }).isRequired,
  theme: objectOf(object)
};

VenueReport.defaultProps = {
  sortVenuesList: null,
  sortVenuesOther: null,
  theme: null
};

export default withTheme(VenueReport);
