import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { PAGE_TITLE, PROFILE_URL } from "./constants";

import HomePage from "./pages/home";
import DownloadPage from "./pages/download";
import NotFoundPage from "./pages/not-found";
import UserPage from "./pages/user";

import GlobalStyle from "./style";

document.title = `${PAGE_TITLE}`;

const Routes = props => (
  <Router {...props}>
    <Fragment>
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/download" component={DownloadPage} />
        <Route path={`${PROFILE_URL}/:username`} component={UserPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Fragment>
  </Router>
);

export default Routes;
