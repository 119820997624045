import React from "react";
import styled from "styled-components";

import { Heading, Wrapper, Outer } from "../components";

const Iframe = styled.iframe`
  width: 100%;
  height: 30em;
  border: 0;
`;

const DownloadPage = () => (
  <Wrapper>
    <Outer>
      <Heading as="h1">D O W N L O A D</Heading>
      <Iframe
        title="download json file"
        src="https://mainstream.ghan.nl/scrobbles.html"
      />
    </Outer>
  </Wrapper>
);

export default DownloadPage;
