import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { isAfter, isSameDay, format, getYear } from "date-fns";
import Parser from "html-react-parser";
import styled from "styled-components";

import { LABEL, BASE_URL, SORTING, DATE_FORMAT } from "../../constants";

import List from "../List";
import { Button, Heading, ListLineup } from "..";
import TagStyle from "../Tag";

const Icon = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  padding: 0 0.5em;
  background-color: ${({ theme }) => theme.color.border};
  color: white;

  ${({ theme }) => theme.media.medium`
    position: inherit;
    margin: calc((-2 * (${theme.spacing.inner})) + 2px)
      calc(-1 * (${theme.spacing.inner})) calc((-2 * (${
    theme.spacing.inner
  })) + 2px)
      0.5em;
  `}
`;

const Item = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: white;
  margin: 0.375em 0;

  > button {
    position: relative;
    padding: ${({ theme }) => theme.spacing.inner};
    width: 100%;

    &:hover ${Icon} {
      background-color: ${({ theme }) => theme.color.dark};
    }
  }

  &.active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    transition: all 0.5s ease 0s;

    > button {
      outline: none;
      position: static;
    }
  }

  .hl {
    color: white;
  }

  &.active,
  &.details,
  &:hover {
    background-color: ${({ theme }) => theme.color.light};

    > span {
      background-color: ${({ theme }) => theme.color.dark};
    }
  }

  &.time--past .hl {
    background-color: ${({ theme }) => theme.color.past};
  }

  &.time--upcoming .hl {
    background-color: ${({ theme }) => theme.color.upcoming};
  }
`;

const Opened = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.inner}
    ${({ theme }) => theme.spacing.inner};

  ${({ theme }) => theme.media.medium`
    display: flex;
  `}
`;

const MatchOverview = styled.div`
  ${({ theme }) => theme.media.medium`
    display: flex;
    align-items: center;
    width: 100%;

    > span {
      margin-left: auto;
    }
  `}
`;

const Tags = styled.div`
  display: flex;
  margin-top: 1em;

  ${({ theme }) => theme.media.medium`
    margin-top: 0;
    margin-left: auto;
  `}
`;

const Tag = styled.span`
  ${TagStyle};
  margin-right: 0.5em;
`;

const MatchInfo = styled.div`
  margin-right: 3em;
  position: relative;

  ${({ theme }) => theme.media.medium`
    margin-right: 1em;
  `}
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.color.medium};
  font-size: 0.938em;
  margin-top: 0.5em;
`;

const DetailsOverview = styled.div`
  ${({ theme }) => theme.media.medium`
    flex-basis: calc(100% / 3);
    margin-right: 2em;
  `}

  li:first-letter {
    text-transform: capitalize;
  }
`;

const Lineup = styled.div`
  margin-top: 1em;

  ${({ theme }) => theme.media.medium`
    margin-top: 0;
    width: 100%;
  `}
`;

const ResultItem = ({
  index,
  itemOpened,
  item,
  highlightName,
  highlightTitle,
  highlightVenue,
  singleArtist,
  onItemClick
}) => {
  const {
    startdate,
    details: { place, country, href, artists }
  } = item;

  return (
    <Item
      className={`${itemOpened === index ? " active" : ""}${
        isAfter(new Date(startdate), new Date())
          ? " time--upcoming"
          : " time--past"
      }`}
    >
      <Button reset onClick={onItemClick}>
        <MatchOverview>
          <MatchInfo>
            <Heading as="h3">
              {item.details.isArtistMatch &&
                item.title.toLowerCase() !== item.name.toLowerCase() &&
                Parser(`${highlightName} @ `)}
              {Parser(`<span>${highlightTitle}</span>`)}
            </Heading>
            <SubTitle>
              {getYear(new Date(startdate))} - {Parser(highlightVenue)}
            </SubTitle>
          </MatchInfo>

          <Tags>
            {isAfter(new Date(startdate), new Date()) ||
            isSameDay(new Date(startdate), new Date()) ? (
              <Tag className="bg--upcoming">{LABEL.upcoming}</Tag>
            ) : (
              <Tag className="bg--past">{LABEL.past}</Tag>
            )}
            {item.isConcert ? (
              <Tag className="bg--concert">{LABEL.concert}</Tag>
            ) : (
              <Tag className="bg--festival">{LABEL.festival}</Tag>
            )}
            {item.isSupportAct && (
              <Tag className="bg--support">{LABEL.support}</Tag>
            )}
            <Icon>{itemOpened === index ? "▲" : "▼"}</Icon>
          </Tags>
        </MatchOverview>
      </Button>

      {itemOpened === index && (
        <Opened>
          <DetailsOverview>
            <Heading as="h4">Details</Heading>
            <List>
              <li>{format(new Date(startdate), DATE_FORMAT)}</li>
              <li>
                {item.isConcert ? `${LABEL.concert}` : `${LABEL.festival}`}
              </li>
              <li>
                {place}, {country}
              </li>

              {item.isConcert ? (
                href && (
                  <li>
                    <a href={`${BASE_URL}${href}`}>
                      {item.title} @{item.venue}
                    </a>
                  </li>
                )
              ) : (
                <Fragment>
                  {!item.details.isEventMatch && !item.details.isVenueMatch && (
                    <li>
                      <a href={`${BASE_URL}${singleArtist[0].href}`}>
                        {item.name}
                      </a>
                    </li>
                  )}
                  <li>
                    <a href={`${BASE_URL}${href}`}>{item.title}</a>
                  </li>
                </Fragment>
              )}
            </List>
          </DetailsOverview>
          <Lineup>
            <Heading as="h4">Line up ({artists.length})</Heading>
            <ListLineup
              fullLineup={artists}
              showItems="4"
              sortDirection={SORTING.asc.direction}
              sortType={SORTING.type.alphabetical}
            />
          </Lineup>
        </Opened>
      )}
    </Item>
  );
};

ResultItem.propTypes = {
  index: PropTypes.number.isRequired,
  itemOpened: PropTypes.number.isRequired,
  item: PropTypes.shape({
    startdate: PropTypes.string.isRequired
  }).isRequired,
  highlightName: PropTypes.string.isRequired,
  highlightTitle: PropTypes.string.isRequired,
  highlightVenue: PropTypes.string.isRequired,
  singleArtist: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired
};

export default ResultItem;
