import { css, createGlobalStyle } from "styled-components";
import "./theme/fonts/index.css";

const IE = css`
  &:after {
    content: "Best visible in another browser.";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1em;
    background: red;
    color: white;
  }
`;

const GlobalStyle = createGlobalStyle`
  :root {
    --outerSpace: 1rem;
  }

  ${({ theme }) => theme.media.medium`
    :root {
      --outerSpace: 2rem;
    }
  `}

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.medium};
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    line-height: ${({ theme }) => theme.font.lineHeight};
    font-size: calc((13 / 16) * 100%);
    font-family: ${({ theme }) => theme.font.secondary};

    @supports (font-variation-settings: normal) {
      font-family: ${({ theme }) => theme.font.primary};
    }

    ${({ theme }) => theme.media.medium`
      font-size: calc((${theme.font.size} / 16) * 100%);
    `}

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      ${IE}
    }

    @supports (-ms-ime-align:auto) and (not (-ms-accelerator:true)) {
      ${IE}
    }
  }

  html, body, #root {
    height: 100%;
  }

  table {
    width: 100%;
    margin-bottom: 1em;
  }

  th {
    text-align: left;
  }

  td,
  th {
    padding: 0.5em 1em;
  }

  a {
    color: ${({ theme }) => theme.color.link};

    &:hover {
      text-decoration: none;
    }
  }

  em {
    font-variation-settings: "ital" 1;
    font-synthesis: none;
  }

  main {
    background-color: ${({ theme }) => theme.color.light};
    min-height: calc(100% - 1.5em);

    ${({ theme }) => theme.media.medium`
      border: 0.75em solid ${theme.color.medium};
      border-right: 0;
    `}
  }

  mark {
    background-color: ${({ theme }) => theme.color.highlight};
  }

  .bg {
    &--upcoming {
      background-color: ${({ theme }) => theme.color.upcoming};
    }

    &--past {
      background-color: ${({ theme }) => theme.color.past};
    }

    &--concert {
      background-color: ${({ theme }) => theme.color.concert};
    }

    &--festival {
      background-color: ${({ theme }) => theme.color.festival};
    }

    &--support {
      background-color: ${({ theme }) => theme.color.support};
    }

    &--default {
      background-color: ${({ theme }) => theme.color.border};
    }
  }

  .border {
    &--upcoming {
      color: ${({ theme }) => theme.color.upcoming};
      border-color: ${({ theme }) => theme.color.upcoming};
    }

    &--past {
      color: ${({ theme }) => theme.color.past};
      border-color: ${({ theme }) => theme.color.past};
    }

    &--concert {
      color: ${({ theme }) => theme.color.concert};
      border-color: ${({ theme }) => theme.color.concert};
    }

    &--festival {
      color: ${({ theme }) => theme.color.festival};
      border-color: ${({ theme }) => theme.color.festival};
    }

    &--support {
      color: ${({ theme }) => theme.color.support};
      border-color: ${({ theme }) => theme.color.support};
    }

    &--default {
      color: ${({ theme }) => theme.color.border};
      border-color: ${({ theme }) => theme.color.border};
    }
  }
`;

export default GlobalStyle;
