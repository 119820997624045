import { handleActions } from "redux-actions";
import { loadUserEvents } from "../actions";

const initialState = [];

export default handleActions(
  {
    [loadUserEvents]: {
      next: (state, { payload }) => payload,
      throw: () => initialState
    }
  },
  initialState
);
