import { isBefore, isSameDay, getYear } from "date-fns";
import { LABEL } from "./constants";

export const pastFilter = item =>
  isBefore(new Date(item.startdate), new Date()) !==
  isSameDay(new Date(item.startdate), new Date());
export const upcomingFilter = item => !pastFilter(item);
export const concertFilter = item => item.isConcert;
export const festivalFilter = item => !concertFilter(item);
export const supportAct = item => item.isSupportAct;

export const six = item => getYear(new Date(item.startdate)) === 2006;
export const seven = item => getYear(new Date(item.startdate)) === 2007;
export const eight = item => getYear(new Date(item.startdate)) === 2008;
export const nine = item => getYear(new Date(item.startdate)) === 2009;
export const ten = item => getYear(new Date(item.startdate)) === 2010;
export const eleven = item => getYear(new Date(item.startdate)) === 2011;
export const twelve = item => getYear(new Date(item.startdate)) === 2012;
export const thirteen = item => getYear(new Date(item.startdate)) === 2013;
export const fourteen = item => getYear(new Date(item.startdate)) === 2014;
export const fifteen = item => getYear(new Date(item.startdate)) === 2015;
export const sixteen = item => getYear(new Date(item.startdate)) === 2016;
export const seventeen = item => getYear(new Date(item.startdate)) === 2017;
export const eighteen = item => getYear(new Date(item.startdate)) === 2018;
export const nineteen = item => getYear(new Date(item.startdate)) === 2019;

export const filterGroups = [
  {
    ids: ["pastSelected", "upcomingSelected"],
    labels: [LABEL.past, LABEL.upcoming],
    className: ["past", "upcoming"],
    filters: {
      pastSelected: pastFilter,
      upcomingSelected: upcomingFilter
    }
  },
  {
    ids: ["concertSelected", "festivalSelected"],
    labels: [LABEL.concert, LABEL.festival],
    className: ["concert", "festival"],
    filters: {
      concertSelected: concertFilter,
      festivalSelected: festivalFilter
    }
  },
  {
    ids: ["supportSelected"],
    labels: [LABEL.support],
    className: ["support"],
    filters: {
      supportSelected: supportAct
    }
  },
  {
    ids: [
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006"
    ],
    labels: [
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006"
    ],
    className: ["default"],
    filters: {
      2019: nineteen,
      2018: eighteen,
      2017: seventeen,
      2016: sixteen,
      2015: fifteen,
      2014: fourteen,
      2013: thirteen,
      2012: twelve,
      2011: eleven,
      2010: ten,
      2009: nine,
      2008: eight,
      2007: seven,
      2006: six
    }
  }
];

const generateORFilter = filters => {
  if (!filters.length) return filters;

  const createSingFilter = filter => item => filter(item);
  const createDualFilter = (filter1, filter2) => item =>
    filter1(item) || filter2(item);

  if (filters.length === 1) {
    return createSingFilter(filters.shift());
  }

  // either 2 or more filters
  const f1 = filters.shift();
  const f2 = filters.shift();
  const filter = createDualFilter(f1, f2);
  filters.push(filter);

  return generateORFilter(filters);
};

export const generateFiltersFromGroup = (filterGroup, flags) => {
  let noFilterSelected = true;
  const filterKeys = Object.keys(filterGroup);
  filterKeys.forEach(filterKey => {
    if (flags[filterKey]) noFilterSelected = false;
  });

  if (noFilterSelected) {
    return [() => true];
  }

  const groupFilters = filterKeys.map(filterKey => {
    const flag = flags[filterKey];
    if (!flag) return () => false;
    return item => {
      const filter = filterGroup[filterKey];
      return filter(item);
    };
  });
  const groupORFilters = generateORFilter(groupFilters);
  return groupORFilters;
};

export const applyAllFilters = (list, filterFlags) => {
  const groupedFilters = filterGroups.map(filterGroup =>
    generateFiltersFromGroup(filterGroup.filters, filterFlags)
  );
  const flatFilters = [].concat(...groupedFilters.map(filter => filter));

  const applyFilterlist = (listToFilter, filterList) => {
    if (!filterList.length) return listToFilter;
    const firstFilter = filterList.shift();
    const filteredList = listToFilter.filter(firstFilter);
    return applyFilterlist(filteredList, filterList);
  };
  return applyFilterlist(list, flatFilters);
};

export const allFilterIds = () =>
  [].concat(...filterGroups.map(group => group.ids.map(id => id)));
