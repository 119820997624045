import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Svg = styled.svg`
  margin-left: 10px;
`;

const getViewBox = name => {
  switch (name) {
    case "popup":
      return "0 0 20 20";
    default:
      return "0 0 32 32";
  }
};

const getPath = (name, props) => {
  switch (name) {
    case "external-link":
      return (
        <Fragment>
          <path
            {...props}
            d="M18 12c-0.6 0-1 0.4-1 1v6c0 0.6-0.4 1-1 1h-11c-0.6 0-1-0.4-1-1v-11c0-0.6 0.4-1 1-1h6c0.6 0 1-0.4 1-1s-0.4-1-1-1h-6c-1.7 0-3 1.3-3 3v11c0 1.7 1.3 3 3 3h11c1.7 0 3-1.3 3-3v-6c0-0.6-0.4-1-1-1z"
          />
          <path
            {...props}
            d="M21.9 2.6c-0.1-0.2-0.3-0.4-0.5-0.5-0.1-0.1-0.3-0.1-0.4-0.1h-6c-0.6 0-1 0.4-1 1s0.4 1 1 1h3.6l-9.3 9.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l9.3-9.3v3.6c0 0.6 0.4 1 1 1s1-0.4 1-1v-6c0-0.1 0-0.3-0.1-0.4z"
          />
        </Fragment>
      );
    case "popup":
      return (
        <path
          {...props}
          d="M16 2h-8.021c-1.099 0-1.979 0.88-1.979 1.98v8.020c0 1.1 0.9 2 2 2h8c1.1 0 2-0.9 2-2v-8c0-1.1-0.9-2-2-2zM16 12h-8v-8h8v8zM4 10h-2v6c0 1.1 0.9 2 2 2h6v-2h-6v-6z"
        />
      );
    default:
      return <path />;
  }
};

const SVGIcon = ({ name, style, fill, width, viewBox }) => (
  <Svg
    width={width}
    style={{ minWidth: width, ...style }}
    height={width}
    viewBox={getViewBox(name) || viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {getPath(name, { fill })}
  </Svg>
);

SVGIcon.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  fill: PropTypes.string,
  width: PropTypes.number,
  viewBox: PropTypes.string
};

SVGIcon.defaultProps = {
  style: {},
  fill: "",
  width: 20,
  viewBox: "0 0 32 32"
};

export default SVGIcon;
