import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

import { DATE_FORMAT, BASE_URL } from "../../constants";

import {
  EventItem,
  Startdate,
  Seperator,
  Heading,
  HeadingContainer,
  ReportList
} from "..";

const VenueList = ({ listItems, title }) =>
  listItems.length ? (
    <Fragment>
      <HeadingContainer>
        <Heading as="h3">
          {title} ({listItems.length})
        </Heading>
      </HeadingContainer>
      <ReportList>
        {listItems.map((item, index) => (
          <EventItem key={index.toString()}>
            <Startdate>
              {format(new Date(item.startdate), DATE_FORMAT)}
            </Startdate>
            <Seperator> - </Seperator>
            <a href={`${BASE_URL}/${item.href}`}>{item.title}</a>
          </EventItem>
        ))}
      </ReportList>
    </Fragment>
  ) : null;

VenueList.propTypes = {
  title: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default VenueList;
