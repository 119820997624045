import React from "react";
import PropTypes from "prop-types";

import { checkUsername, pageTitle } from "../../utils";
import NotFoundPage from "../not-found";
import { Wrapper, Outer } from "../../components";

const FilePage = ({
  match: {
    params: { username }
  }
}) => {
  pageTitle(username);
  const file = checkUsername(username);
  if (!file) return <NotFoundPage username={username} />;

  return (
    <Wrapper>
      <Outer>
        <pre>{JSON.stringify(file, null, 2)}</pre>
      </Outer>
    </Wrapper>
  );
};

FilePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      username: PropTypes.string
    }).isRequired
  }).isRequired
};

export default FilePage;
