import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { node, func } from "prop-types";
import isNil from "lodash/fp/isNil";

import { ButtonIcon } from "../Button";

const Background = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 9999;
`;

const Container = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 100px);
  overflow-y: auto;

  ${({ theme }) => theme.media.medium`
    width: 50%;
  `}
`;

const Inner = styled.div`
  position: relative;
  padding: 3em;
  background-color: white;

  h2 a:hover {
    color: ${({ theme }) => theme.color.link};
    text-decoration: none;
  }
`;

const Close = styled(ButtonIcon)`
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  font-size: 2em;
`;

const Modal = ({ children, onCloseRequest }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleKeyUp = e => {
      const keys = {
        27: () => {
          e.preventDefault();
          onCloseRequest();
          window.removeEventListener("keyup", handleKeyUp, false);
        }
      };

      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    };

    const handleOutsideClick = e => {
      if (!isNil(modalRef)) {
        if (!modalRef.current.contains(e.target)) {
          onCloseRequest();
          document.removeEventListener("click", handleOutsideClick, false);
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp, false);
    document.addEventListener("click", handleOutsideClick, false);

    return () => {
      window.removeEventListener("keyup", handleKeyUp, false);
      document.removeEventListener("click", handleOutsideClick, false);
    };
  }, [onCloseRequest]);

  return (
    <Background>
      <Container>
        <Inner ref={modalRef}>
          {children}
          <Close reset type="button" onClick={() => onCloseRequest()}>
            ×
          </Close>
        </Inner>
      </Container>
    </Background>
  );
};

Modal.propTypes = {
  children: node.isRequired,
  onCloseRequest: func.isRequired
};

export default Modal;
