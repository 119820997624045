import { css } from "styled-components";

const TagStyle = css`
  padding: 1px 3px 3px;
  border-radius: 3px;
  color: white;
  border: 1px solid transparent;
`;

export default TagStyle;
