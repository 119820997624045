import { createActions } from "redux-actions";

// Example without `redux-actions`
export const ITEM_TYPE = "ITEM_TYPE";
export const setItemType = itemType => ({
  type: ITEM_TYPE,
  payload: { itemType }
});

export const {
  connection,
  toggleModal,
  searchUpdate,
  searchUser,
  getSelectedItem,
  getEventListSameVenue,
  loadUserEvents,
  sortEventsUpcoming,
  sortEventsPast,
  toggleVenueReport,
  toggleVenueList,
  sortVenuesList,
  sortVenuesOther
} = createActions(
  "CONNECTION",
  "TOGGLE_MODAL",
  "SEARCH_UPDATE",
  "SEARCH_USER",
  "GET_SELECTED_ITEM",
  "GET_EVENT_LIST_SAME_VENUE",
  "LOAD_USER_EVENTS",
  "SORT_EVENTS_UPCOMING",
  "SORT_EVENTS_PAST",
  "TOGGLE_VENUE_REPORT",
  "TOGGLE_VENUE_LIST",
  "SORT_VENUES_LIST",
  "SORT_VENUES_OTHER"
);

export const fetchUserEvents = username => async dispatch => {
  try {
    let url = `/api/users/${username}`;
    url =
      process.env.NODE_ENV !== "production"
        ? `https://lastevents.jasperleferink.now.sh${url}`
        : url;
    const response = await fetch(url);

    if (response.status !== 200)
      throw new Error("Some descriptive error message");

    const payload = await response.json();
    const newPayload = JSON.stringify(payload);
    const comparePayload = newPayload === localStorage.getItem(username);

    if (!comparePayload) {
      localStorage.setItem(username, newPayload);
    }

    return dispatch(loadUserEvents(payload));
  } catch (err) {
    return dispatch(loadUserEvents(err));
  }
};
