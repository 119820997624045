import { handleActions } from "redux-actions";
import { sortVenuesList, sortVenuesOther } from "../actions";
import { SORTING } from "../constants";

const {
  asc: { direction: ASC },
  desc: { direction: DESC }
} = SORTING;

export default handleActions(
  {
    [sortVenuesList]: (state, { payload }) => {
      switch (true) {
        case !payload && state.sortDirectionVenuesList === ASC:
          return {
            ...state,
            sortDirectionVenuesList: DESC
          };
        case !payload && state.sortDirectionVenuesList === DESC:
          return {
            ...state,
            sortDirectionVenuesList: ASC
          };
        case payload === ASC || payload === DESC:
          return { ...state, sortDirectionVenuesList: payload };
        default:
          return state;
      }
    },
    [sortVenuesOther]: (state, { payload }) => {
      switch (true) {
        case !payload && state.sortDirectionVenuesOther === ASC:
          return {
            ...state,
            sortDirectionVenuesOther: DESC
          };
        case !payload && state.sortDirectionVenuesOther === DESC:
          return {
            ...state,
            sortDirectionVenuesOther: ASC
          };
        case payload === ASC || payload === DESC:
          return { ...state, sortDirectionVenuesOther: payload };

        default:
          return state;
      }
    }
  },
  {
    sortDirectionVenuesList: DESC,
    sortDirectionVenuesOther: DESC
  }
);
