import styled from "styled-components";

const SearchForm = styled.form`
  border-bottom: 1px solid ${({ theme }) => theme.color.dark};

  input[type="search"] {
    font-size: 1.5em;
    background-color: ${({ theme }) => theme.color.light};

    ${({ theme }) => theme.media.medium`
      font-size: 2em;
    `}
  }
`;

export default SearchForm;
