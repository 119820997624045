import React, { Fragment, useEffect } from "react";
import {
  bool,
  func,
  string,
  shape,
  arrayOf,
  objectOf,
  object
} from "prop-types";
import { format } from "date-fns";
import { debounce } from "lodash";
import styled, { withTheme } from "styled-components";
import { sortOrder, getListBy, filterEvents, pageTitle } from "../../../utils";
import { SORTING, BASE_URL, DATE_FORMAT, LABEL } from "../../../constants";
import SVGIcon from "../../../icons";
import NotFoundPage from "../../not-found";
import ErrorPage from "../../error";

import {
  Heading,
  Wrapper,
  Inner,
  ListLineup,
  Notification,
  Modal,
  VenueReport,
  EventReport,
  SearchForm,
  SearchInput,
  SearchResults,
  VenueList
} from "../../../components";

const Title = styled(Heading)`
  padding: 1em ${({ theme }) => theme.spacing.outer} 0;

  ${({ theme }) => theme.media.medium`
    padding: 0;
    margin-bottom: ${theme.spacing.vertical};
  `}
`;

const Highlight = styled.a`
  color: ${({ theme }) => theme.color.dark};

  em {
    background-color: ${({ theme }) => theme.color.highlight};
    padding: 5px;
  }
`;

const ProfilePage = ({
  isOnline,
  setItemType,
  itemType,
  isModalOpen,
  toggleModal,
  doesUserExist,
  searchString,
  searchUpdate,
  matches,
  match,
  getSelectedItem,
  selectedItem,
  eventListSameVenue,
  getEventListSameVenue,
  userEvents,
  fetchUserEvents,
  theme
}) => {
  const { username } = match.params;

  useEffect(() => {
    const getUserEvents = () => {
      fetchUserEvents(username);
    };
    getUserEvents();
    pageTitle(username);
  }, [username, fetchUserEvents, isOnline]);

  const handleContent = () => {
    if (!itemType) return <h1>NO TYPE</h1>;

    if (itemType === "venue") {
      const sortedList = sortOrder(eventListSameVenue, SORTING.desc.direction);

      return (
        <Fragment>
          <Heading as="h2">
            {selectedItem.name} ({selectedItem.count})
          </Heading>

          <VenueList
            title={LABEL.upcoming}
            listItems={getListBy(LABEL.upcoming, sortedList)}
          />
          <VenueList
            title={LABEL.past}
            listItems={getListBy(LABEL.past, sortedList)}
          />
        </Fragment>
      );
    }

    if (itemType === "event")
      return (
        <Fragment>
          <Heading as="h2">
            {selectedItem.href ? (
              <a href={`${BASE_URL}${selectedItem.href}`}>
                {selectedItem.title}
                <SVGIcon name="external-link" width={20} />
              </a>
            ) : (
              selectedItem.title
            )}
          </Heading>
          <ul>
            <li>{format(new Date(selectedItem.startdate), DATE_FORMAT)}</li>
            <li>
              {selectedItem.venue.name}, {selectedItem.venue.place},{" "}
              {selectedItem.venue.country}
            </li>
          </ul>
          <div style={{ marginTop: "1em" }}>
            <Heading as="h4">Line up ({selectedItem.artists.length})</Heading>
            <ListLineup
              fullLineup={selectedItem.artists}
              sortDirection={SORTING.asc.direction}
              sortType={SORTING.type.alphabetical}
            />
          </div>
        </Fragment>
      );

    return null;
  };

  const displayArtistMatches = inputValue => {
    debounce(() => {
      searchUpdate(inputValue);
    }, 10)();
  };

  const getLocalStorage = JSON.parse(localStorage.getItem(username));
  const localStorageEvents = filterEvents(getLocalStorage);
  const offlineLocalStorage = !isOnline && localStorageEvents !== null;
  const offlineNoLocalStorage = !isOnline && localStorageEvents === null;

  if (!isOnline && offlineNoLocalStorage)
    return <ErrorPage title="You are offline" />;
  if (isOnline && doesUserExist === false) return <NotFoundPage />;

  return (
    <Fragment>
      <main>
        {offlineLocalStorage ? (
          <Notification>
            <Heading as="h1">App is offline, data can be outdated</Heading>
          </Notification>
        ) : (
          <Fragment>
            <SearchForm
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <SearchInput
                placeholder="Search artist, event or venue"
                onChange={displayArtistMatches}
                onClick={searchUpdate}
                onKeyDown={e => {
                  const keyCode = e.keyCode || e.which;
                  const ENTER = 13;
                  if (keyCode === ENTER) {
                    e.target.blur();
                  }
                }}
              />
            </SearchForm>
            <SearchResults
              searchString={searchString}
              matchesFromProps={matches}
              sortDirection={SORTING.desc.direction}
            />
          </Fragment>
        )}

        <Wrapper>
          <Inner>
            <Title as="h1">
              Reports for{" "}
              <Highlight href={`${BASE_URL}/user/${username}`}>
                <em>{username}</em>
                <SVGIcon name="external-link" fill={theme.color.link} />
              </Highlight>{" "}
            </Title>

            {!offlineLocalStorage &&
            (userEvents === null || userEvents.length === 0) ? (
              <Notification>L O A D I N G</Notification>
            ) : (
              <Fragment>
                <VenueReport
                  listItems={
                    offlineLocalStorage ? localStorageEvents : userEvents
                  }
                  showItems="5"
                  isOpened={false}
                  onItemClick={props => {
                    setItemType("venue");
                    toggleModal();
                    getSelectedItem(props.selectedItem);
                    getEventListSameVenue(props.eventListWithSameVenue);
                  }}
                />
                <EventReport
                  events={offlineLocalStorage ? localStorageEvents : userEvents}
                  isOpened
                  onItemClick={props => {
                    setItemType("event");
                    toggleModal();
                    getSelectedItem(props.selectedItem);
                  }}
                />
              </Fragment>
            )}
            {isModalOpen && (
              <Modal onCloseRequest={toggleModal}>{handleContent()}</Modal>
            )}
          </Inner>
        </Wrapper>
      </main>
    </Fragment>
  );
};

const artist = arrayOf(
  shape({
    name: string.isRequired,
    href: string.isRequired
  })
);

const event = shape({
  title: string.isRequired,
  href: string.isRequired,
  startdate: string.isRequired,
  artists: artist,
  venue: shape({
    name: string.isRequired,
    place: string.isRequired,
    country: string.isRequired
  })
});

const match = shape({
  details: shape({
    artists: artist,
    country: string,
    href: string,
    isArtistMatch: bool,
    isEventMatch: bool,
    isVenueMatch: bool,
    place: string
  }),
  isConcert: bool,
  isSupportAct: bool,
  name: string,
  startdate: string,
  title: string,
  venue: string
});

ProfilePage.propTypes = {
  isOnline: bool.isRequired,
  match: shape({
    params: shape({
      username: string
    }).isRequired
  }).isRequired,
  isModalOpen: bool.isRequired,
  toggleModal: func.isRequired,
  doesUserExist: bool,
  searchString: string,
  searchUpdate: func.isRequired,
  userEvents: arrayOf(event),
  matches: arrayOf(match),
  fetchUserEvents: func.isRequired,
  setItemType: func.isRequired,
  itemType: string,
  selectedItem: shape({}),
  getSelectedItem: func.isRequired,
  eventListSameVenue: arrayOf(event),
  getEventListSameVenue: func.isRequired,
  theme: objectOf(object),
  eventListWithSameVenue: arrayOf(event)
};

ProfilePage.defaultProps = {
  doesUserExist: null,
  searchString: null,
  userEvents: [],
  matches: [],
  itemType: "",
  selectedItem: null,
  eventListSameVenue: [],
  theme: null,
  eventListWithSameVenue: []
};

export default withTheme(ProfilePage);
