import React from "react";
import { string } from "prop-types";
import { Wrapper, Outer, Notification, Heading } from "../components";

const ErrorPage = ({ title }) => {
  return (
    <Wrapper>
      <Outer>
        <Notification>
          <Heading as="h1">{title}</Heading>
        </Notification>
      </Outer>
    </Wrapper>
  );
};

ErrorPage.propTypes = {
  title: string
};

ErrorPage.defaultProps = {
  title: "Error on the page!"
};

export default ErrorPage;
