import React, { useState } from "react";
import styled from "styled-components";
import { string, func } from "prop-types";

const FormContainer = styled.div`
  position: relative;

  input {
    appearance: none;
    border: 0;
  }

  input[type="search"] {
    width: 100%;
    padding: ${({ theme }) => theme.spacing.outer} 2em
      ${({ theme }) => theme.spacing.outer}
      ${({ theme }) => theme.spacing.outer};
    font-family: inherit;
    box-shadow: none;

    ::-webkit-search-cancel-button {
      display: none;
    }
  }
`;

const Reset = styled.input`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: none;
  font-size: 2em;
  line-height: 0;
  padding: 1rem;
  margin: 0;
  cursor: pointer;

  ${({ theme }) => theme.media.medium`
    right: 0.5em;
  `}
`;

const SearchInput = ({ placeholder, onChange, onClick, onKeyDown }) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <FormContainer>
      <input
        type="search"
        placeholder={placeholder}
        aria-label={placeholder}
        onChange={e => {
          const val = e.target.value;
          setInputValue(val);
          onChange(val);
        }}
        onKeyDown={onKeyDown}
        value={inputValue}
      />

      {inputValue && inputValue.length > 0 && (
        <Reset
          type="reset"
          value="×"
          onClick={() => {
            if (onClick) onClick(null);
            setInputValue("");
          }}
        />
      )}
    </FormContainer>
  );
};

SearchInput.propTypes = {
  placeholder: string.isRequired,
  onChange: func.isRequired,
  onClick: func.isRequired,
  onKeyDown: func
};

SearchInput.defaultProps = {
  onKeyDown: () => {}
};

export default SearchInput;
