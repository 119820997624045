import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  min-height: 100%;
`;

export const Inner = styled.div`
  ${({ theme }) => theme.media.medium`
    padding: ${theme.spacing.outer};
  `}
`;

export const Outer = styled(Inner)`
  padding: ${({ theme }) => theme.spacing.outer};
`;
