import styled, { css } from "styled-components";

import { ButtonReport, Heading } from "..";

export const ReportContainer = styled.div``;

const ReportList = styled.ul`
  padding-left: 0.5em;

  ${({ theme }) => theme.media.medium`
    > li {
      margin-left: 1em;
    }
  `}
`;

export const Inner = styled.div`
  position: relative;
  background-color: white;

  ${({ theme }) => theme.media.medium`
    z-index: 2;

    &:before,
    &:after {
      content: "";
      z-index: -2;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: rotate(2deg);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background-color: white;
    }

    &:before {
      transform: rotate(0);
      z-index: -1;
    }
  `}
`;

export const Outer = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.vertical};

  &:first-of-type {
    z-index: 10;
  }

  ${({ theme }) => theme.media.medium`
    margin-left: ${theme.spacing.outer};
    margin-right: ${theme.spacing.outer};
  `}

  ${ButtonReport}, ${ReportContainer} {
    padding-left: ${({ theme }) => theme.spacing.outer};
    padding-right: ${({ theme }) => theme.spacing.outer};
  }

  ${ButtonReport} ${Heading} {
    border-bottom: 2px solid transparent;
  }

  ${ButtonReport} {
    padding-top: ${({ theme }) => theme.spacing.vertical};
    padding-bottom: ${({ theme }) => theme.spacing.vertical};
    background-color: transparent;

    &:hover ${Heading} {
      border-color: ${({ theme }) => theme.color.link};
    }
  }

  ${props =>
    props.isOpened &&
    css`
      padding-bottom: 2em;

      ${Inner} > div {
        padding-bottom: 2em;
      }
    `};
`;

export default ReportList;
