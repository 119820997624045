import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Filters = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  color: white;

  li {
    display: inline-block;

    > div {
      margin-right: 1em;

      &:last-of-type {
        border-right: 1px solid ${({ theme }) => theme.color.border};
        padding-right: 1em;
      }
    }

    &:last-child > div:last-of-type {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }

  span + span {
    margin-left: 1em;
  }
`;

const ListFilters = ({ children }) => <Filters>{children}</Filters>;

ListFilters.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ListFilters;
