export const BASE_URL = "https://www.last.fm";
export const PROFILE_URL = "";
export const DATE_FORMAT = "dd-MM-yyyy";
export const PAGE_TITLE = `Lastevents`;

export const LABEL = {
  past: "past",
  upcoming: "upcoming",
  concert: "concert",
  festival: "festival",
  support: "support act"
};

export const SORTING = {
  asc: {
    direction: "asc",
    icon: "↑"
  },
  desc: {
    direction: "desc",
    icon: "↓"
  },
  type: {
    date: "startdate",
    alphabetical: "name",
    count: "count"
  }
};
