import styled, { css } from "styled-components";

export const Button = styled.button`
  font-family: inherit;
  font-size: 1em;
  line-height: ${({ theme }) => theme.font.lineHeight};
  background-color: ${({ theme }) => theme.color.link};
  border: none;
  border-radius: 4px;
  color: white;
  padding: 0.5em 0.75em;

  &:hover {
    background-color: ${({ theme }) => theme.color.dark};
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  ${props =>
    props.small &&
    css`
      padding: 0.25em 0.5em;
      font-size: 0.875em;
    `} ${props =>
    props.reset &&
    css`
      background-color: transparent;
      border: none;
      border-radius: 0;
      text-align: left;
      color: inherit;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    `};
`;

export const ButtonIcon = styled(Button)`
  margin-left: 0.5em;
  padding: 0;
  height: ${({ theme }) => theme.spacing.vertical};
  width: ${({ theme }) => theme.spacing.vertical};
`;

export const ButtonReport = styled(Button)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  color: black;
  background-color: white;

  + button {
    border-top: 1px solid ${({ theme }) => theme.color.border};
  }

  span {
    margin-left: auto;
  }

  &:hover span {
    color: ${({ theme }) => theme.color.link};
  }
`;

export const ButtonMoreVenues = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.vertical};
  border-radius: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.color.dark};
  border-top: 1px solid ${({ theme }) => theme.color.medium};

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.color.link};
  }
`;
