import { connect } from "react-redux";
import { sortEventsUpcoming, sortEventsPast } from "../../../actions";
import EventsComponent from "./Component";

const mapStateToProps = ({ sortDirectionEvents }) => ({
  sortDirectionEvents
});

const mapDispatchToProps = {
  sortEventsUpcoming,
  sortEventsPast
};

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connectToStore(EventsComponent);
