import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { userProfileMap } from "../../utils";

import ProfilePage from "./Profile";
import FilePage from "./file";
import NotFoundPage from "../not-found";
import { PROFILE_URL } from "../../constants";

export default () => (
  <Switch>
    {Object.keys(userProfileMap).map(key => (
      <Route
        key={Math.random()}
        exact
        path={`/${key}`}
        render={() => <Redirect to={`${PROFILE_URL}/${userProfileMap[key]}`} />}
      />
    ))}
    <Route exact path={`${PROFILE_URL}/:username/file`} component={FilePage} />
    <Route exact path={`${PROFILE_URL}/:username`} component={ProfilePage} />
    <Route component={NotFoundPage} />
  </Switch>
);
