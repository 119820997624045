import styled from "styled-components";

import { Button } from "..";

export const EventItem = styled.li`
  display: flex;
`;

export const Startdate = styled.span`
  white-space: nowrap;
  min-width: 70px;

  ${({ theme }) => theme.media.medium`
    min-width: 80px;
  `}
`;

export const Seperator = styled.span`
  margin-right: 10px;
`;

export const More = styled(Button)`
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.color.link};
    text-decoration: underline;
  }

  > svg {
    margin-top: calc(${({ theme }) => theme.spacing.vertical} * 0.25);
  }
`;
