import { ITEM_TYPE } from "../actions";

const itemType = (state = "", action) => {
  switch (action.type) {
    case ITEM_TYPE:
      return action.payload.itemType;

    default:
      return state;
  }
};

export default itemType;
