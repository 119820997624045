import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
/* eslint-disable import/no-extraneous-dependencies */
import { composeWithDevTools } from "redux-devtools-extension";
import { ThemeProvider } from "styled-components";
import { connection } from "./actions";
import * as theme from "./theme";
import rootReducer from "./reducers";
import Routes from "./routes";
import registerServiceWorker from "./registerServiceWorker";

const middleware = applyMiddleware(thunk);
const enchanceStore =
  process.env.NODE_ENV !== "production"
    ? composeWithDevTools(middleware)
    : middleware;

const store = createStore(rootReducer, enchanceStore);

store.dispatch(connection(navigator.onLine));
window.addEventListener("online", () => store.dispatch(connection(true)));
window.addEventListener("offline", () => store.dispatch(connection(false)));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
